import React, { useState, useCallback } from "react"
import { Link } from "gatsby"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

import Layout from "../../components/layout"
import FooterCaption from "../../components/footer-caption"

import One from "../../../content/assets/other-items/1.jpg"
import Two from "../../../content/assets/other-items/2.jpg"
import Three from "../../../content/assets/other-items/3.jpg"
import Four from "../../../content/assets/other-items/4.jpg"
import Five from "../../../content/assets/other-items/5.jpg"

const OtherItems = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const photos = [
    {
      src: One,
      width: 1.4,
      height: 1,
      caption: `Bowl - 9 inch`,
    },
    {
      src: Two,
      width: 1,
      height: 1,
      caption: `Bowl - 6 inch`,
    },
    {
      src: Three,
      width: 1,
      height: 1,
      caption: `Spider Bowl - Top View. 10 inch Bowl with 3 spiders`,
    },
    {
      src: Four,
      width: 1.5,
      height: 0.5,
      caption: `Spider Bowl - Side View`,
    },
    {
      src: Five,
      width: 2,
      height: 0.8,
      caption: `Letter Opener`,
    }
  ]

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Layout>
      <article>
        <h1>Other Items</h1>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                components={{ FooterCaption }}
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <Link
          style={{ display: "inline-block", marginTop: "24px" }}
          to="/gallery"
        >
          ← Back to Gallery
        </Link>
      </article>
    </Layout>
  )
}

export default OtherItems
